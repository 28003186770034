<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="List"
                :showBtn="true"
                :showAddNew="false"
                title="Sales Invoice Add"
                @onClickCloseButton="navigateToListPage"
            />

            <div class="row mt-2 gy-1">
                <div class="col-md-3">
                    <label class="form-label">Invoice Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.date"
                    >
                </div>
                <div class="col-md-3" v-if="false">
                    <label class="form-label">Invoice No</label>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                        <div class="input-group input-group-merge invoice-edit-input-group">
                            <div class="input-group-text">
                                <span>{{ prefix }}-</span>
                            </div>
                            <input type="number" min="1" class="form-control invoice-edit-input" placeholder=""
                                   v-model="serial">
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="margin-top: 23px;"
                        @click="openCustomerAddModal"
                    >
                        New Customer
                    </button>
                </div>
            </div>
            <div class="row gy-1">
                <div :class="salesOrderId && 'disabled-element'" class="col-md-3">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Party: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal('party')">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <AsyncSelect
                        placeholder="Select Party"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :additional-query="{ with_mobile: 1, roles: 'customer' }"
                        :format-label="formatPatientLabel"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Project</label>
                    <v-select
                        placeholder="Select Project"
                        v-model="formData.project_id"
                        :options="projects"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Business Unit</label>
                    <v-select
                        placeholder="Select Business"
                        v-model="formData.business_id"
                        :options="business"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Cost Centre</label>
                    <v-select
                        placeholder="Select Cost Centre"
                        v-model="formData.cost_centre_id"
                        :options="costCentres"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Ship from (Warehouse)</label>
                    <v-select
                      placeholder="Select Warehouse Location"
                      v-model="formData.location_id"
                      :options="locations"
                      label="text"
                      :reduce="text => text.id"
                      @option:selected="resetForm"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Receivable Accounts</label>
                    <v-select
                        placeholder="Account Head"
                        v-model="formData.account_head_id"
                        :options="accountPayable"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Vat Accounts</label>
                    <v-select
                        placeholder="Vat Payable Account Head"
                        v-model="formData.vat_payable_account_head_id"
                        :options="vatPayable"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Order No/PI No</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add order number"
                        v-model="formData.order_number"
                    >
                </div>
                <div class="col-md-3">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Sales Person</label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal('sales_person')">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <AsyncSelect
                        placeholder="Select Sales Person"
                        v-model="salesPerson"
                        :api-service="fetchContactProfiles"
                        :additional-query="{ with_mobile: 1 }"
                        :format-label="formatPatientLabel"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Commission %</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="number"
                        placeholder="Please add commission %"
                        v-model="formData.commission_percent"
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Commission Amount</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="number"
                        placeholder="Please add commission amount"
                        v-model="formData.commission_amount"
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">LC No</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add LC number"
                        v-model="formData.lc_number"
                    >
                </div>
            </div>
        </div>

        <div class="px-2">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link" data-bs-toggle="tab"  aria-controls="home" role="tab" aria-selected="true">Item Details</a>
                </li>
                <li class="nav-item">
                    <a @click="formData.has_item_detail = false" :class="{'active' : !formData.has_item_detail}" class="nav-link" data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
                </li>
            </ul>
        </div>

        <div class="px-3">
            <Invoice
                class="mt-2"
                v-for="(data, index) in accountsDetails"
                :key="getUniqueDomId"
                :index="index"
                :data="data"
                :products="products"
                :accountHeads="accountHeads"
                :vatRate="vatRate"
                :isItem="isItem"
                :locationId="formData.location_id"
                :contact-profile-id="selectedCustomerId"
                :business="business"
                :projects="projects"
                :costCentres="costCentres"
                :re-render="reRenderInvoice"
                @onClose="removeBill"
            />
            <Invoice
                class="mt-2"
                v-for="(data, index) in itemsDetails"
                :key="getUniqueDomId"
                :index="index"
                :data="data"
                :products="products"
                :accountHeads="accountHeads"
                :vatRate="vatRate"
                :isItem="isItem"
                :locationId="formData.location_id"
                :contact-profile-id="selectedCustomerId"
                :business="business"
                :projects="projects"
                :costCentres="costCentres"
                :re-render="reRenderInvoice"
                @onClose="removeBill"
            />
        </div>

        <div v-if="!salesOrderId" class="px-2 mt-2">
            <button @click="addNewBill" class="btn btn-primary">Add line</button>
        </div>

        <div class="px-2">
            <div class="row justify-content-end">
                <div class="col-12 col-sm-3">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-5">
                                    <label class="col-form-label" for="first-name">Sub total</label>
                                </div>
                                <div class="col-sm-7">
                                    <input
                                        v-model="subTotal"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-5">
                                    <label class="col-form-label" for="first-name">VAT</label>
                                </div>
                                <div class="col-sm-7">
                                    <input
                                        v-model="totalVAT"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-5">
                                    <label class="col-form-label" for="first-name">Total</label>
                                </div>
                                <div class="col-sm-7">
                                    <input
                                        v-model="beforeAdjTotal"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                        @click="openAdvanceModal"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-5">
                                    <label class="col-form-label" for="first-name">Advance Adjustment Amount</label>
                                </div>
                                <div class="col-sm-7">
                                    <input
                                        v-model="formData.advance_amount"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                        @click="openAdvanceModal"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-5">
                                    <label class="col-form-label" for="first-name">Total</label>
                                </div>
                                <div class="col-sm-7">
                                    <input
                                        v-model="total"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-5">
                                    <label class="col-form-label" for="first-name">Discount / Round up Adj</label>
                                </div>
                                <div class="col-sm-7">
                                    <input
                                        v-model.trim="formData.adjustment_amount"
                                        type="number"
                                        class="form-control discount-adj ml-auto text-right"
                                        placeholder="amount" :min="0"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="discountAmount > 0">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">Select discount head</label>
                                </div>
                                <div class="col-sm-8">
                                    <v-select
                                        placeholder="Select Discount Head"
                                        v-model="formData.discount_head_id"
                                        :options="salesAccountHeads"
                                        label="name"
                                        :reduce="name => name.id"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-5">
                                    <label class="col-form-label" for="first-name">Net Bill</label>
                                </div>
                                <div class="col-sm-7">
                                    <input
                                        v-model.trim="grandTotal"
                                        type="number" class="form-control discount-adj ml-auto text-right"
                                        placeholder="amount" :min="0"
                                        @keyup="updateAdjustmentAmount"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 px-2">
            <div class="mb-1">
                <label class="form-label" for="description">Memo</label>
                <vField
                    as="textarea"
                    name="description"
                    type="number"
                    class="form-control"
                    v-model="formData.description"
                />
            </div>
        </div>

        <div class="pb-5 px-2 mt-4">
            <div class="d-flex flex-wrap gap-1 gy-2">
                <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)" class="btn btn-primary">Save</button>
                <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)" class="btn btn-primary">Save & New</button>
                <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true, true)" class="btn btn-primary">Print</button>
                <button :disabled="saveButtonLoader || saveNewButtonLoader" class="btn btn-primary">Share</button>
                <button :disabled="productLoader" @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
            </div>
        </div>
         <Loader v-if="loader"/>
         <ContactDropdownFilter 
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            type="customer"
         />

        <AddCustomerModal
            v-if="store.state.isModalOpenTwo"
            @onCreateProfile="onCreateProfile"
        />
        <AdvanceReceiptSelectionModal
            v-if="store.state.isAdvanceModalOpen"
            :receipts="advanceReceiptList"
            :total="parseFloat(beforeAdjTotal)"
        />
    </div>
</template>

<script setup>
import {ref, inject, onMounted, computed, watch, provide} from 'vue'
import { useStore } from "vuex";
import {vatRate} from '@/data/inventory.js'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleProjects from "@/services/modules/procurement/project";
import Loader from '@/components/atom/LoaderComponent'
import TitleButton from '@/components/atom/TitleButton'
import Invoice from '@/components/molecule/company/inventory/sales/Invoice.vue'
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import pdfPrinter from '@/services/utils/pdfPrinter';
import handleBarcode from "@/services/modules/barcode";
import handleCompany from "@/services/modules/company";
import handleHospital from '@/services/modules/hospital'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import AddCustomerModal from "@/components/molecule/company/inventory/AddCustomerModal.vue";
import AdvanceReceiptSelectionModal
    from "@/components/molecule/company/inventory/electronic-invoice/AdvanceReceiptSelectionModal.vue";
import {getUniqueDomId} from "@fullcalendar/core/internal";

const router = useRouter()
const route = useRoute()
const store = useStore()
let prefix = ref('')
let serial = ref('')

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const companyInfo = ref({});
const barcode = ref('');
const qrcode = ref('');
const reRenderInvoice = ref(false);

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {fetchBusinessList} = handleCBusinesses()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles, fetchContactAdvanceReceipts} = handleContact()
const { fetchProjects } = handleProjects()
const { fetchCostCentreList } = handleCostCentres()
const { exportToPDF } = pdfPrinter();
const { fetchBarcode } = handleBarcode();
const {fetchCompanyInfo} = handleCompany();
const { notesByType } = handleHospital()
const { formatPatientLabel } = useAsyncDropdownHelper();

let loader =ref(false)
let productLoader =ref(false)
let saveButtonLoader=ref(false)
let saveNewButtonLoader =ref(false)
let products = ref([])
let accountHeads = ref([])
let contactType = ref('');
let business = ref([])
let locations = ref([])
let accountPayable = ref([])
let vatPayable = ref([])
let projects = ref([])
let costCentres = ref([])
const invoice = ref({});
const barcodeQuery = ref('');
const invoiceNotes = ref([]);
const contactProfile = ref(null);
const salesPerson = ref(null);
const advanceReceiptList = ref([]);
let salesAccountHeads = ref([])
const grandTotal = ref(null);

const companyId = computed(() => {
    return route.params.companyId
})

onMounted(async () => {
    loader.value=true
    // const productQuery = `?company_id=${companyId.value}&product_type=finished_goods`
    let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=invoice_journal`);
    prefix.value = voucher.prefix;
    serial.value = voucher.serial;
    formData.value.date =  new Date().toISOString().split('T')[0]

    const companyQuery = `?company_id=${companyId.value}`
    let projectQuery = companyQuery + '&with_donors=1';

    const productRes = fetchProductList(companyQuery)
    const accountHeadRes = fetchAccountHead(companyQuery)
    const businessRes = fetchBusinessList(companyQuery)
    const businessLocationRes = fetchBusinessLocationsList(companyQuery)
    const payableRes = rest.fetchAccountReceivable(companyQuery)
    const vatPayableRes = rest.fetchVatPayable(companyQuery)
    const projectRes = fetchProjects(projectQuery)
    const costCenterRes = fetchCostCentreList(companyQuery)
    const fetchCompany = fetchCompanyInfo(companyId.value)
    const salesAccountHeadsRes = rest.getAccountHeadBySlag("sales_accounts", companyQuery)

    Promise.all([
        productRes.then(res=> {
            if(res.data) products.value = res.data
        }),
        accountHeadRes.then(res=> {
            if(res.data) accountHeads.value = res.data
        }),
        businessRes.then(res=> {
            if(res.data) business.value = res.data
        }),
        businessLocationRes.then(res=> {
          if(res.data) locations.value = res.data
        }),
        projectRes.then(res=> {
            if(res.data) projects.value = res.data
        }),
        costCenterRes.then(res=> {
            if(res.data) costCentres.value = res.data
        }),
        payableRes.then(res=> {
            if(res.data) accountPayable.value = res.data
            if(res.data?.length) {
                formData.value.account_head_id = res.data[0].id
            }
        }),
        vatPayableRes.then(res=> {
            if(res.data) vatPayable.value = res.data
            if(res.data?.length) {
                formData.value.vat_payable_account_head_id = res.data[0].id
            }
        }),
        fetchCompany.then(res => {
            if(res){
                companyInfo.value = res.data
            }
        }),
        getInvoiceNotes(),
        salesAccountHeadsRes.then(res => {
            if(res.data) salesAccountHeads.value = res.data
        })
    ])
    .then(async () => {

        await fetchSingleSalesOrder();

        loader.value=false
    })
    .catch(()=>{loader.value=false})

})

let formData = ref({
    company_id: companyId,
    contact_profile_id: null,
    account_head_id: null,
    vat_payable_account_head_id: null,
    business_id: null,
    project_id: null,
    cost_centre_id: null,
    location_id: null,
    discount_head_id: null,
    status: 'active',
    bill_number: '',
    date: '',
    has_item_detail: true,
    description: '',
    account_details: [],
    order_number: null,
    sales_person_id: null,
    commission_percent: null,
    commission_amount: 0,
    advance_amount: 0,
    adjustment_amount: 0,
    lc_number: null,
    item_details: [
        {
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 0 ,
            vat_amount: 0,
            description: "",
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }
    ],
})

provide('formData', formData);
const hasItemDetails = computed(() => {
    return formData.value.has_item_detail
})
const itemsDetails = computed(() => {
    return formData.value.account_details
})
const accountsDetails = computed(() => {
    return formData.value.item_details
})
const isItem = computed(() => {
    return !!formData.value.has_item_detail
})
const salesOrderId = computed(() => route.query.sales_order_id)
const commissionPercent = computed(() => formData.value.commission_percent)

const subTotal = computed(() => {
    let subTotal=0

    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
            const offerDiscount = item.offer_discount ?? 0;
            subTotal += parseFloat((item.quantity * item.rate) - item.discount_amount - offerDiscount)
        })
    }

    if(!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
            const offerDiscount = item.offer_discount ?? 0;
            if(item.amount) {subTotal += parseFloat(item.amount - item.discount_amount - offerDiscount)}
        })
    }

    return subTotal.toFixed(4)
})

const totalVAT = computed(() => {
    let vat=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
          if(item.vat_amount) {
            vat += parseFloat(item.vat_amount)
          }
        })
    }
    if(!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
          if(item.vat_amount) {
            vat += parseFloat(item.vat_amount)
          }
        })
    }

    return vat.toFixed(4)
})

const beforeAdjTotal = computed(() => (parseFloat(subTotal.value) + parseFloat(totalVAT.value)).toFixed(4));

const total = computed(() => {
    return (parseFloat(subTotal.value) + parseFloat(totalVAT.value) - parseFloat(formData.value.advance_amount)).toFixed(4)
})
const selectedCustomerId = computed(() => formData.value.contact_profile_id)
const discountAmount = computed(() => formData.value.adjustment_amount);

watch(hasItemDetails, (newValue) => {
    if(!newValue) {
        delete formData.value.item_details
        formData.value.account_details = [{
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            vat: 0,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null,
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }]
    }
    if(newValue) {
        delete formData.value.account_details
        formData.value.item_details = [{
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 0,
            vat_amount: 0,
            description: "",
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }]
    }
})

watch(commissionPercent, (newValue) => {
  if(newValue) {
    formData.value.commission_amount = (total.value/100) * newValue;
    return;
  }
  formData.value.commission_amount = 0;
})

watch(total, (newValue) => {
  grandTotal.value = total.value - discountAmount.value;

  if(commissionPercent.value) {
    formData.value.commission_amount = (newValue/100) * commissionPercent.value;
    return;
  }

  formData.value.commission_amount = 0;
})

watch(discountAmount, (newValue, oldValue) => {
    if (! newValue || newValue < 0) {
        grandTotal.value = total.value;
        formData.value.adjustment_amount = null;
        return;
    }

    if (newValue !== oldValue) {
        grandTotal.value = total.value - discountAmount.value
    }
})

const onOpenContactSearchModal = (type) => {
    contactType.value = type
    store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
    if(contactType.value === 'sales_person') {
        salesPerson.value = contact
        return;
    }
    contactProfile.value = contact
}

watch(contactProfile, (newValue) => {
    formData.value.contact_profile_id = newValue ? newValue.id : null
})

watch(selectedCustomerId, (newValue) => {
    const companyQuery = `?company_id=${companyId.value}`
    advanceReceiptList.value = []
    formData.value.advance_amount = 0;
    const totalAmount = parseFloat(total.value)

    if(newValue) {
        fetchContactAdvanceReceipts(newValue, companyQuery).then(res => {
            if (res.data && res.data.length) {
                const availableAmount = res.data[0].available_amount;
                advanceReceiptList.value = res.data
                formData.value.advance_receipt_id = res.data[0].id;
                formData.value.advance_amount =  totalAmount && totalAmount > availableAmount ? availableAmount : totalAmount;
            }
        }).catch(() => console.log("Something went wrong"))
    }
})

const getInvoiceNotes = async () => {
    const companyQuery = `?company_id=${route.params.companyId}&show_default_only=1`;
    await notesByType(companyQuery, "invoice_terms").then((res) => {
        if(! res.status) return;
        invoiceNotes.value = res.data.filter((item) => item.full_desc);
    })
}

const openCustomerAddModal = () => {
    store.state.isModalOpenTwo = true
}

const onCreateProfile = (customer) => {
    const companyQuery = `?company_id=${companyId.value}`
    fetchContactProfiles(companyQuery).then((res) => {
        contacts.value = res.data;
        formData.value.contact_profile_id = customer.id;
    })
}

const openAdvanceModal = () => {
    if(!selectedCustomerId.value) {
        showError("Please select customer first")
        return;
    }

    if(
        !advanceReceiptList.value ||
        (Array.isArray(advanceReceiptList.value) && !advanceReceiptList.value.length)
    ) {
        showError("No advance receipts found");
        return;
    }

     store.state.isAdvanceModalOpen = true
}

const fetchSingleSalesOrder = async () => {

    if(!salesOrderId.value) return;

    const companyQuery = `?company_id=${route.params.companyId}`;
    const res = await rest.fetchSalesOrderById(salesOrderId.value, companyQuery);

    if(res.status) {
        const { bill_number, date, ...data } = res.data;

        formData.value = { date: formData.date, ...data }
        formData.value.order_number = bill_number;

        if(res.data.contact) {
            contactProfile.value = res.data.contact
        }

        if(res.data.has_item_detail) {
            formData.value.item_details = res.data.general
            formData.value.has_item_detail = true
            reRenderInvoice.value = true
        }

        if(!res.data.has_item_detail) {
            formData.value.account_details = res.data.ledgers
            formData.value.has_item_detail = false
        }

        formData.value.sales_order_master_id = res.data.id;
        formData.value.status = 'active';
        delete formData.value.sale_type;
    }
}

const getRouteName = () => {
    if (route.query.redirect_route && route.query.redirect_route !== '') {
        return route.query.redirect_route;
    }

    return 'sales-list';
}

function navigateToListPage() {
    let routeName = getRouteName();

    router.push({
        name: routeName,
        params: route.params, 
        query: route.query
    })
}

const goToPrint = (id) => {
    const user = store.state.user.user
    const companyQuery = `?company_id=${route.params.companyId}`;

    rest.fetchSalesBill(id, companyQuery).then( (res) =>{
        if( res ){
            invoice.value = res.data;
            let qrcodeData = JSON.stringify({
                ref_no: invoice.value.bill_number,
                ref_date: invoice.value.date,
                created_by: user.name,
                created_date_time: formatDate(new Date())
            })
            barcodeQuery.value = `?code=${invoice.value.bill_number}&qrcode=${qrcodeData}`;     
        }}).then (() => {
            fetchBarcode(barcodeQuery.value).then( (res) => {
            barcode.value = res.barcode;
            qrcode.value = res.qrcode
        }).then( () => {
            exportToPDF(companyInfo.value, invoice.value, barcode.value, qrcode.value, user.name, 1, invoiceNotes.value)
        })  
    }).catch( () => {
        console.log("Something went wrong");
    })
}

const formatDate = (date) => {
    var formattedDate = date.toISOString().split('T')[0];
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
    return formatToDdMmYy(formattedDate) + ' ' + formattedTime
}

const formatToDdMmYy = (date) => {
    var dat = date.split('-');
    return `${dat[2]}-${dat[1]}-${dat[0]}`;
}

const updateAdjustmentAmount = () => {

    if (total.value <= 0) {
        formData.value.adjustment_amount = null;
        return;
    }

    formData.value.adjustment_amount = parseFloat((total.value - grandTotal.value).toFixed(4));
}

function removeBill(index) {
    if(hasItemDetails.value) {
        formData.value.item_details.splice(index, 1)
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.splice(index, 1)
    }
}

function addNewBill() {
    if(hasItemDetails.value) {
        formData.value.item_details.push({
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 0,
            vat_amount: 0,
            description: '',
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        })
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.push({
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            vat: 0,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null,
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        })
    }
}
function handleSubmit(redirect=false, print=false) {
    
    if (! formData.value.vat_payable_account_head_id){
      showError('Please select a vat payable account head');
      return;
    }
    if(redirect) {
        saveButtonLoader.value = true
    } else {
        saveNewButtonLoader.value = true
    }
    formData.value.sales_person_id = salesPerson.value ? salesPerson.value.id : null
    formData.value.bill_number = prefix.value+'-'+serial.value
    if(!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0]
    let copyFormData = JSON.parse(JSON.stringify(formData.value))
    copyFormData.has_item_detail = formData.value.has_item_detail ? 1 : 0
    if(isItem.value) {
        delete copyFormData.account_details
        copyFormData.item_details = formData.value.item_details
    }else {
         delete copyFormData.item_details
        copyFormData.account_details = formData.value.account_details
    }

    rest.storeBillPayment(copyFormData)
    .then(res => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
        productLoader.value = false
        if(res.status) {
            showSuccess(res.message)
            if(print) goToPrint(res.data.id)
            if(redirect) navigateToListPage()
            resetForm()
        }
        if(!res.status) {
            showError(res.message)
        }
    })
    .catch(() => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
    })
}

// have to refactor
function resetForm() {
    if(!isItem.value) {
        formData.value.account_details = [{
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            vat: 0,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null,
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }]
    }
    if(isItem.value) {
        formData.value.item_details = [{
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 0,
            vat_amount: 0,
            description: "",
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }]
    }
}

</script>

<style scoped>
.filter-label {
    padding-right: 0.5rem !important;
    height: 34%;
}
</style>